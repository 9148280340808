body {
  font-family: 'Comfortaa', cursive;
}

.nav {
  display: flex;
  justify-content: flex-end;
}

.nav-item {
  font-size:150%;
}

a:link, a:visited {
  color: salmon;
}

@media  screen and (max-width: 600px) {
  .nav-item {
    flex: 50%;
    width: auto;
  }
  .nav {
    flex-wrap: wrap;
  }
  .header-container {
    flex-direction: column;
  }
}