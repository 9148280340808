.header {
    display: flex;
    flex-wrap: nowrap
}

.heading {
    margin-left: 2vh;
    margin-top: 1vh;
    font-size: 300%;
}

@media  screen and (max-width: 600px) {
    .header {
        justify-content: center;
    }
    .heading {
        text-align: center;
    }
  }